@font-face {
    font-family: 'Pretendard';
    src: url(../font/Pretendard-Regular.subset.woff2);
    font-weight: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url(../font/Pretendard-Medium.subset.woff2);
    font-weight: 500;
}

@font-face {
    font-family: 'Pretendard';
    src: url(../font/Pretendard-Bold.subset.woff2);
    font-weight: bold;
}

* {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    word-break: break-all;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-touch-callout: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Pretendard", sans-serif;
}

body {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    touch-action: pan-x pan-y;
}

button, label, input, textarea {
    -webkit-appearance: none; /* ios border radius issue */
    -webkit-border-radius: 0; /* ios border radius issue */
    font-family: inherit;
    cursor: inherit;
}

input:focus {
    outline: none;
}

button {
    color: #000; /* ios color blue issue */
    font-size: inherit;
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
